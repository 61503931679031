body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: 'black';
  font-family: "Roboto", sans-serif;
   box-sizing:border-box;
   overflow-x:hidden;
}

p {
   margin: 0;
    padding: 0;
}

code {
   font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 14px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

::-webkit-scrollbar-thumb {
  background: #888; 
}


::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
